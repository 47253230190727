/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,200&family=Sen:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: var(--font-sen);
  font-family: var(--font-pop);
  scrollbar-width: thin;
  scrollbar-color: #6eb69d #0c111a;
}

:root {
  /* fonts */
  --font-sen: "Sen", sans-serif;
  --font-pop: "Poppins", sans-serif;
  cursor: url("./assets/icons/myCursor.png");

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: var(--font-pop);

  /* font-family: var(--font-sen); */

  /* background: var(--color-primary-background); */
  /* color: var(--color-white); */
  line-height: 1.7;
}

/* reuse-able-classes */
.just-center-c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.just-center-r {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* landing-animation----------------------------------- */
.imWrapper {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: column;
  box-sizing: content-box;
}

.imWrapper .words span {
  font-size: 2.7rem;
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-family: var(--font-sen);
  animation: spin_words 7s infinite;
}
@keyframes spin_words {
  10% {
    transform: translateY(-112%);
  }
  25% {
    transform: translateY(-100%);
  }

  35% {
    transform: translateY(-212%);
  }
  50% {
    transform: translateY(-200%);
  }
  60% {
    transform: translateY(-312%);
  }
  75% {
    transform: translateY(-300%);
  }
  80% {
    transform: translateY(-412%);
  }
  100% {
    transform: translateY(-400%);
  }
}

.social_icons_github :hover {
  color: #c3c3c3;
  transform: translateY(-1.5px);
}

.social_icons_linkedin :hover {
  color: #007ab5;
  transform: translateY(-1.5px);
}
.social_icons_gmail :hover {
  color: #e75a4d;
  transform: translateY(-1.5px);
}
.social_icons_stackoverflow :hover {
  color: #ed7c24;
  transform: translateY(-1.5px);
}
.social_icons_twitter :hover {
  color: #00aced;
  transform: translateY(-1.5px);
}

.social_icons {
  transition: transform 200ms;
}

.slide_down {
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

.about_main {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s,
    transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
}

.circle {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
}

.circle:nth-of-type(1) {
  height: 190px;
  width: 175px;
  animation: rt 0.4s infinite linear;
  box-shadow: 0 0 1px 0 #6eb69d, inset 0 0 10px 0 #6eb69d;
}
.circle:nth-of-type(2) {
  height: 195px;
  width: 180px;
  animation: rt 0.8s infinite linear;
  box-shadow: 0 0 1px 0 #6ee8bd, inset 0 0 10px 0 #6ee8bd;
}

.circle:nth-of-type(3) {
  width: 210px;
  height: 185px;
  animation: rt 1.5s infinite linear;
  box-shadow: 0 0 1px 0 #6fbfc3, inset 0 0 10px 0 #6fbfc3;
}
.circle:nth-of-type(4) {
  width: 210px;
  height: 190px;
  animation: rt 2s infinite linear;
  box-shadow: 0 0 1px 0 #60c4cf, inset 0 0 10px 0 #60c4cf;
}
.circle:nth-of-type(5) {
  width: 215px;
  height: 195px;
  animation: rt 2.3s infinite linear;
  box-shadow: 0 0 1px 0 #70d7b3, inset 0 0 10px 0 #70d7b3;
}

@keyframes rt {
  100% {
    transform: rotate(360deg);
  }
}
